import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Success from "./Success";
import Cancel from "./Cancel";
import "antd/dist/reset.css";
import "./App.css";
import { Layout, theme, Card } from "antd";

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <Layout.Content style={{ padding: "16px", margin: "0 auto" }}>
        <Card>
          <Switch>
            <Route exact={true} path="/" component={Home} />
            <Route exact={true} path="/success" component={Success} />
            <Route exact={true} path="/cancel" component={Cancel} />
          </Switch>
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default App;
