import React from "react";
import { Typography } from "antd";

class Success extends React.Component {
  render() {
    return (
      <>
          <Typography.Title>Order Successful</Typography.Title>
          <Typography.Text>Thank you for your order, a confirmation has been sent to your email.</Typography.Text>
      </>
    );
  }
}

export default Success;
