import React from "react";
import { Typography, Button, Space } from "antd";

class Cancel extends React.Component {
  render() {
    return (
      <>
        <Typography.Title>Order Cancelled</Typography.Title>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Typography.Text>:( you didn't complete your order.</Typography.Text>
          <a href="/">
            <Button block type="primary">
              Start Over
            </Button>
          </a>
        </Space>
      </>
    );
  }
}

export default Cancel;
