import * as React from "react";
import { Button, Form, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ORDER_API_URL = "https://api.luxedesignscheshire.co.uk/orders"
const ADULT_PRICE = 2215;
const JUNIOR_PRICE = 1995;
const PRODUCTS = {
  "":{
    name: "",
    price: 0,
  },
  "7-8": {
    name: "Age 7 - 8",
    price: JUNIOR_PRICE,
  },
  "9-11": {
    name: "Age 9 - 11",
    price: JUNIOR_PRICE,
  },
  "12-13": {
    name: "Age 12 - 13",
    price: JUNIOR_PRICE,
  },
  "x-small": {
    name: "X-Small",
    price: ADULT_PRICE,
  },
  "small": {
    name: "Small",
    price: ADULT_PRICE,
  },
  "medium": {
    name: "Medium",
    price: ADULT_PRICE,
  },
  "large": {
    name: "Large",
    price: ADULT_PRICE,
  },
  "x-large": {
    name: "X-Large",
    price: ADULT_PRICE,
  },
  "xx-large": {
    name: "XX-Large",
    price: ADULT_PRICE,
  },
};

const formItemLayout = {
  labelCol: {
    // xs: { span: 24 },
    // sm: { span: 4 },
  },
  wrapperCol: {
    // xs: { span: 24 },
    // sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    // xs: { span: 24, offset: 0 },
    // sm: { span: 20, offset: 4 },
  },
};

const displayPrice = price => {
  if (price === 0) {
    return "";
  }
  return `(£${(price / 100).toFixed(2)})`;
}
const formatProduct = key => {
  if (key === "") {
    return "";
  }
  return `${PRODUCTS[key].name} ${displayPrice(PRODUCTS[key].price)}`;
};

export const OrderForm = () => {
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const onProductChange = () => {
    const {hoodies} = form.getFieldsValue();
    setTotalPrice(hoodies.reduce((price, hoody) => {
      const product = PRODUCTS[hoody];
      return (price + product.price);
    }, 0));
  };
  const finished = formValues => {
    setIsLoading(true);
    fetch(ORDER_API_URL, {
      body: JSON.stringify(formValues),
      method: "POST"
    }).then(res => res.json())
    .then(order => {
      window.location.href = order.url;
    }).catch(() => setIsLoading(false))
  };
  return (
    <Form onFinish={finished} {...formItemLayout} form={form} layout="vertical">
      <Form.Item
        label="Your Name"
        name="name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email Address"
        name="email"
        validateTrigger={["onBlur"]}
        rules={[
          { type: "email", required: true, message: "Please enter your email" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Childs Name"
        name="childsName"
        rules={[{ required: true, message: "Please enter your childs name" }]}
      >
        <Input />
      </Form.Item>
      <Form.List
        name="hoodies"
        rules={[
          {
            validator: async (_, hoodies) => {
              if (!hoodies || hoodies.length < 1) {
                return Promise.reject(new Error("Please add at least 1 hoody"));
              }
            },
          },
        ]}
        initialValue={[""]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(field => (
              <Form.Item
                label="Hoody Size"
                required={false}
                key={field.key}
                {...formItemLayout}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please select a size or remove this item.",
                    },
                  ]}
                  noStyle
                >
                  <Select style={{ width: (fields.length > 1 ? "85%" : "100%") }} onChange={onProductChange}>
                    {Object.keys(PRODUCTS).map(key => (
                      <Select.Option
                        value={PRODUCTS[key].value}
                        key={key}
                      >
                        {formatProduct(key)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
              >
                Add an additional hoody
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item wrapperCol={1}>
        <Button type="primary" htmlType="submit" block loading={isLoading} disabled={totalPrice === 0}>
          Place Order {displayPrice(totalPrice)}
        </Button>
      </Form.Item>
    </Form>
  );
};
